import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import NewsPost from "../components/NewsPost"
import Search from "../components/Search"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newsPostData: this.props.data.allSanityNewsPost.nodes,
    }
    this.handleSearch = this.handleSearch.bind(this)
  }
  handleSearch(input) {
    input = input.toLowerCase()
    let filteredNewsPostData = this.props.data.allSanityNewsPost.nodes
    filteredNewsPostData = filteredNewsPostData.filter(newsPost => {
      let headline = newsPost.headline.toLowerCase()
      let content = newsPost._rawContent[0].children[0].text.toLowerCase()
      let containsString = false
      if (headline && headline.includes(input)) containsString = true
      if (content && content.includes(input)) containsString = true
      return containsString
    })
    if (input.length) {
      this.setState({ newsPostData: filteredNewsPostData })
    } else {
      let newsPostData = this.props.data.allSanityNewsPost.nodes
      this.setState({ newsPostData })
    }
  }

  renderNewsPosts() {
    let newsPostData = this.state.newsPostData
    let newsPosts = newsPostData.map(newsPost => {
      return <NewsPost key={newsPost._id} content={newsPost} />
    })
    if (newsPosts.length) {
      return newsPosts
    } else {
      return <p>Finner ingen nyheter om dette...</p>
    }
  }
  render() {
    let newsPosts = this.renderNewsPosts()
    return (
      <Layout>
        <Search search={this.handleSearch} />
        <div className="news-post-container">{newsPosts}</div>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSanityNewsPost(sort: { fields: _updatedAt, order: DESC }) {
      nodes {
        headline
        _rawContent(resolveReferences: { maxDepth: 10 })
        _id
        _updatedAt
      }
    }
  }
`
export default IndexPage
