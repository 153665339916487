import React from "react"
import searchIcon from "../../assets/search-icon.svg"

class Search extends React.Component {
  render() {
    return (
      <div className="search">
        <div className="search__input">
          <img
            src={searchIcon}
            className="search__input-icon"
            alt="search icon"
          />
          <input
            type="text"
            onChange={event => this.props.search(event.target.value)}
            placeholder="Søk i nyheter ..."
          />
        </div>
      </div>
    )
  }
}

export default Search
