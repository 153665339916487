import React from "react"
import { serializers } from "../../helpers/blockContentSerializer.js"
import BlockContent from "@sanity/block-content-to-react"

const NewsPost = ({ content }) => {
  let date = content._updatedAt.replace(/[A-Z]/g, " ")
  date = date.substring(0, date.length - 4)
  let clock = date.split(" ").pop()
  let year = date.slice(0, 4)
  let day = date.slice(8, 10)
  let month = date.substring(5, 7)
  return (
    <div className="news-post">
      <div className="news-post__header">
        <span className="news-post__date">{`${day}.${month}.${year} ${clock}`}</span>
        <h2>{content.headline}</h2>
      </div>
      <BlockContent blocks={content._rawContent} serializers={serializers} />
    </div>
  )
}

export default NewsPost
